<template>
  <div>
    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-4">
        <a-select
          showSearch
          placeholder="Select a School"
          optionFilterProp="children"
          class="select-box"
          @change="handleChangeSchool"
          :filterOption="filterOption">
            <a-select-option 
              v-for="school in schoolList"
              :key="school.id" 
              :value="school.id">
                {{school.Name}}
            </a-select-option>
        </a-select>
      </div>
       <div class="col-sm-12 col-md-4">
         <a-select
          showSearch
          placeholder="Select a Campus"
          optionFilterProp="children"
          class="select-box"
          :disabled="campusList.length === 0"
          @select="handleChangeCampus"
          :filterOption="filterOption">
          <a-select-option 
            v-for="campus in campusList"
            :key="campus.id"
            :value="campus.id">
              {{campus.Name}}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <a-select
          showSearch
          placeholder="Select a Class"
          optionFilterProp="children"
          class="select-box"
          :disabled="classList.length === 0"
          @select="handleChangeClass"
          :filterOption="filterOption">
            <a-select-option 
              v-for="item in classList"
              :key="item.id"
              :value="item.id">
                {{item.Name}}
            </a-select-option>
        </a-select>
      </div>
    </div>
    <a-table
      :columns="columns" 
      :dataSource="branchList" 
      :pagination="false"
      :loading="tableLoading"
      rowKey="id">
        <span slot="status" slot-scope="tag">
          <a-tag :color="(tag === 1 ? 'green' : 'volcano')">
            {{tag === 1 ? 'Active' : 'Deleted'}}
          </a-tag>
        </span>
        <span slot="action" slot-scope="record">
          <a-popconfirm
            title="Are you sure delete this branch?"
            @confirm="deleteBranch(record.id)"
            okText="Yes"
            cancelText="No">
              <a href="#">Delete</a>
          </a-popconfirm>
        </span>
    </a-table>
  </div>
</template>
<script>
import campusService from '@/services/campus';
import classService from '@/services/class';
import branchService from '@/services/branch';

const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Branch Name',
      dataIndex: 'Name',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: 'Action',
      scopedSlots: { customRender: 'action' },
    },
  ];

export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      classList: [],
      branchList: [],
      columns,
      tableLoading: false,
    }
  },
  methods: {
    async deleteBranch(branchId){
      const result = await branchService.deleteBranch(branchId);
      if(result) this.$message.success('Branch Deleted!'); 
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleChangeSchool(schoolId) {
      const result = await campusService.getCampusList(schoolId);
      this.campusList = result;
    },
    async handleChangeCampus(campusId) {
      const result = await classService.getClassList(campusId);
      this.classList = result;
    },
    async handleChangeClass(classId) {
      this.tableLoading = true;
      const result = await branchService.getBranchList(classId);
      this.branchList = result;
      this.tableLoading = false;
    }
  }
}
</script>
<style scoped>
.select-box {
  width: 100%;
  margin: 10px 0px;
}
</style>