<template>
  <a-form :form="form" @submit.prevent="validateBranch" class="mt-3">
    <a-form-item label="School" :label-col="{ sm: 4, md: 3 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
        <a-select
          showSearch
          placeholder="Select a School"
          optionFilterProp="children"
          @change="handleChangeSchool"
          v-decorator="[
            'SchoolID',
            { rules: [{ required: true, message: 'Please select a school!' }] },
          ]"
          :filterOption="filterOption">
            <a-select-option 
              v-for="school in schoolList"
              :key="school.id" 
              :value="school.id">
                {{school.Name}}
            </a-select-option>
        </a-select>
    </a-form-item>
    <a-form-item label="Campus" :label-col="{ sm: 4, md: 3 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
        <a-select
          showSearch
          placeholder="Select a Campus"
          optionFilterProp="children"
          @change="handleChangeCampus"
          :disabled="campusList.length === 0"
          v-decorator="[
              'CampusID',
              { rules: [{ required: true, message: 'Please select a campus!' }] },
            ]"
          :filterOption="filterOption">
          <a-select-option 
            v-for="campus in campusList"
            :key="campus.id"
            :value="campus.id">
              {{campus.Name}}
            </a-select-option>
          </a-select>
    </a-form-item>
    <a-form-item label="Class" :label-col="{ sm: 4, md: 3 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
        <a-select
          showSearch
          placeholder="Select a Class"
          optionFilterProp="children"
          :disabled="classList.length === 0"
          v-decorator="[
              'ClassID',
              { rules: [{ required: true, message: 'Please select a class!' }] },
            ]"
          :filterOption="filterOption">
            <a-select-option 
              v-for="item in classList"
              :key="item.id"
              :value="item.id">
                {{item.Name}}
            </a-select-option>
        </a-select>
      </a-form-item>
    <a-form-item label="Name" :label-col="{ sm: 4, md: 3 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
      <a-input placeholder="Name" v-decorator="['Name', { rules: [{ required: true, message: 'Please input a branch name!'}] }]"></a-input>
    </a-form-item>
    <a-form-item label="Description" :label-col="{ sm: 5, md: 3 }" :wrapper-col="{ xs: 24, sm: 19, md: 10 }">
      <a-input placeholder="Description" v-decorator="['Description', { rules: [{ required: true, message: 'Please input a branch description!'}] }]"></a-input>
    </a-form-item>
    <a-form-item label="Status" :label-col="{ sm: 4, md: 3 }" :wrapper-col="{ xs: 24, sm: 20, md: 10}">
      <a-select
        placeholder="Select a Status"
        v-decorator="[
            'Status',
            { initialValue: 1 },
            { rules: [{ required: true, message: 'Please select a status!' }] },
          ]">
        <a-select-option :value="1">
            Active
        </a-select-option>
        <a-select-option :value="0">
            Deleted
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ xs: 24, md: {span: 3, offset: 6 }}">
      <a-button htmlType="submit" type="primary" shape="round" :block="true">Add</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import campusService from '@/services/campus';
import classService from '@/services/class';
import branchService from '@/services/branch';

export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      classList: [],
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'branch' });
  },
  methods: {
    validateBranch() {
      this.form.validateFields( (err, values) => {
        if (!err) {
          this.createBranch(values);
        }
      });
    },
    async createBranch(values) {
      const result = await branchService.createBranch(values);
      if(result) {
        this.$message.success('Branch Created!');
        this.form.resetFields();
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleChangeSchool(schoolId) {
      const result = await campusService.getCampusList(schoolId);
      this.campusList = result;
    },
    async handleChangeCampus(campusId) {
      const result = await classService.getClassList(campusId);
      this.classList = result;
    },
  }
}
</script>
<style scoped>

</style>